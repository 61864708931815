export const TIERS = {
  STARTER: "Starter",
  GROWTH: "Growth",
  ENTERPRISE: "Enterprise",
}
export const tiers = [
  {
    name: TIERS.STARTER,
    href: "/sign-up",
    priceMonthly: 19,
    description: "Quis suspendisse ut fermentum neque vivamus non tellus.",
  },
  {
    name: TIERS.GROWTH,
    href: "/contact-sales",
    priceMonthly: 49,
    description: "Quis eleifend a tincidunt pellentesque. A tempor in sed.",
  },
  {
    name: TIERS.ENTERPRISE,
    href: "/contact-sales",

    description:
      "Orci volutpat ut sed sed neque, dui eget. Quis tristique non.",
  },
]

export const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Molestie lobortis massa.",
        tiers: { Starter: true, Growth: true, Enterprise: true },
      },
      {
        name: "Urna purus felis.",
        tiers: { Starter: true, Growth: true, Enterprise: true },
      },
      {
        name: "Tellus pulvinar sit dictum.",
        tiers: { Growth: true, Enterprise: true },
      },
      {
        name: "Convallis.",
        tiers: { Growth: "Up to 20 users", Enterprise: "Up to 50 users" },
      },
    ],
  },
  {
    name: "Reporting",
    features: [
      {
        name: "Adipiscing.",
        tiers: { Starter: true, Growth: true, Enterprise: true },
      },
      {
        name: "Eget risus integer.",
        tiers: { Growth: true, Enterprise: true },
      },
      { name: "Gravida leo urna velit.", tiers: { Enterprise: true } },
      {
        name: "Elementum ut dapibus mi feugiat cras nisl.",
        tiers: { Enterprise: true },
      },
    ],
  },
  {
    name: "Support",
    features: [
      {
        name: "Sit dignissim.",
        tiers: { Starter: true, Growth: true, Enterprise: true },
      },
      { name: "Congue at nibh et.", tiers: { Growth: true, Enterprise: true } },
      {
        name: "Volutpat feugiat mattis.",
        tiers: { Growth: true, Enterprise: true },
      },
      {
        name: "Tristique pellentesque ornare diam sapien.",
        tiers: { Enterprise: true },
      },
    ],
  },
]
